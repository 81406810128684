import React, { createContext, useContext, useEffect, useState } from "react"
import { DocumentResponseType } from "../api/responseBuilders/document"

import { useSearchDocuments } from "../api/queries/document"
import {
  convertAllDocumentsToMarkdown,
  convertDocumentToMarkdown
} from "../utils/ai/prompt"
import { useTeamContext } from "./TeamContext"
import { GenieSpecVersion } from "../api/domainModels/versioning"

interface DocumentContextType {
  searchDocumentResults: DocumentResponseType[]
  setSearchDocumentResults: React.Dispatch<
    React.SetStateAction<DocumentResponseType[]>
  >
  selectedDocument: DocumentResponseType | null
  setSelectedDocument: React.Dispatch<
    React.SetStateAction<DocumentResponseType | null>
  >
  parentDocumentId: string | undefined
  setParentDocumentId: React.Dispatch<React.SetStateAction<string | undefined>>
  documentTitles: Record<string, string>
  setDocumentTitles: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >
  createPrompt: () => Promise<{
    documentPrompt: string
    imageUrls: string[]
  }>
  createAllDocumentPrompt: () => Promise<{
    documentPrompt: string
    imageUrls: string[]
  }>
  getAllDocumentsAndImageUrls: () => Promise<{
    documentPrompt: string
    imageUrls: string[]
  }>
  allMarkdownDocuments: string | null
  setAllMarkdownDocuments: React.Dispatch<React.SetStateAction<string | null>>
  latestCommitId: string | null
  setLatestCommitId: React.Dispatch<React.SetStateAction<string | null>>
  isDocumentUpdated: boolean
  createCheckSpecificationImplementation: () => Promise<
    | {
        documentPrompt: string
        imageUrls: string[]
      }
    | undefined
  >
}

const DocumentContext = createContext<DocumentContextType | undefined>(
  undefined
)

export const EditorProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [searchDocumentResults, setSearchDocumentResults] = useState<
    DocumentResponseType[]
  >([])
  const [selectedDocument, setSelectedDocument] =
    useState<DocumentResponseType | null>(null)
  const [parentDocumentId, setParentDocumentId] = useState<string | undefined>()
  const { currentSelectedProject } = useTeamContext()
  const [documentTitles, setDocumentTitles] = useState<Record<string, string>>(
    {}
  )
  const [isDocumentUpdated, setIsDocumentUpdated] = useState(false)
  const [allMarkdownDocuments, setAllMarkdownDocuments] = useState<
    string | null
  >(null)
  const { data } = useSearchDocuments({
    projectId: currentSelectedProject?.id,
    enabled: !!currentSelectedProject?.id,
    versionId: currentSelectedProject?.currentGenieSpecVersion?.id,
    fields: [
      "id",
      "name",
      "docTypeId",
      "specifications",
      "order",
      "nextVersionSpecifications",
      "children"
    ],
    archived: false
  })
  const [latestCommitId, setLatestCommitId] = useState<string | null>(null)

  useEffect(() => {
    if (!data) return
    setSearchDocumentResults(data.data.documents)
  }, [data])

  useEffect(() => {
    if (searchDocumentResults) {
      const checkForUpdates = (docs: DocumentResponseType[]): boolean => {
        return docs.some((doc) => {
          if (doc.nextVersionSpecifications) return true
          if (doc.children && doc.children.length > 0) {
            return checkForUpdates(doc.children)
          }
          return false
        })
      }

      const isDocumentUpdated = checkForUpdates(searchDocumentResults || [])
      setIsDocumentUpdated(isDocumentUpdated)
    }
  }, [searchDocumentResults])

  const createPrompt = async () => {
    if (!data?.data?.documents) throw new Error("No documents found")
    const { documents, images } = await convertAllDocumentsToMarkdown({
      allDocuments: data?.data.documents
    })

    const documentPrompt = `
    Here are the folder structure and specifications of the documents. Use this as a reference and context.
    ${documents}

    You must FOCUS on the document ${selectedDocument!.name}.
    You should mainly review ${selectedDocument!.name} document and I'll ask you something about this doc.
    `

    return {
      documentPrompt,
      imageUrls: images.map((image) => image.src)
    }
  }

  const createCheckSpecificationImplementation = async () => {
    const { documents, images } = await convertAllDocumentsToMarkdown({
      allDocuments: searchDocumentResults
    })

    const updatedSpecs = findUpdatedSpecs(searchDocumentResults)
    const updatedSpecsDiff = await generateSpecsDiff(updatedSpecs)

    const documentPrompt = `
    Here are the folder structure and specifications of all documents:
    ${documents}

    The following specifications have been updated:
    ${updatedSpecsDiff}
    `

    return {
      documentPrompt,
      imageUrls: images.map((image) => image.src)
    }
  }

  const findUpdatedSpecs = (
    docs: DocumentResponseType[]
  ): DocumentResponseType[] => {
    return docs.reduce((acc: DocumentResponseType[], doc) => {
      if (doc.nextVersionSpecifications) {
        acc.push(doc)
      }
      if (doc.children && doc.children.length > 0) {
        acc.push(...findUpdatedSpecs(doc.children))
      }
      return acc
    }, [])
  }

  const generateSpecsDiff = async (
    updatedSpecs: DocumentResponseType[]
  ): Promise<string> => {
    let diffString = ""
    for (const spec of updatedSpecs) {
      const oldContent = await convertDocumentToMarkdown(spec)
      const newContent = await convertDocumentToMarkdown({
        ...spec,
        specifications: spec.nextVersionSpecifications!
      })
      diffString += `
      Document: ${spec.name}
      Diff:
      ${generateDiff(oldContent.specifications, newContent.specifications)}
      `
    }
    return diffString
  }

  const generateDiff = (oldContent: string, newContent: string): string => {
    // Implement a simple diff algorithm here
    // For now, we'll just return both contents
    return `
    Old content:
    ${oldContent}

    New content:
    ${newContent}
    `
  }

  const createAllDocumentPrompt = async () => {
    if (!data?.data?.documents) throw new Error("No documents found")

    const { documents, images } = await convertAllDocumentsToMarkdown({
      allDocuments: data?.data.documents
    })

    const documentPrompt = `
    You are a system specification document AI.
    The documents are structured hierarchically.

    I'll show you the structure of the documents and their specifications.
    This JSON is fetched by search document api. Specifications is stored as Prosemirror schema. JSON itself is not important, focus on the content, structure, images.

    ${documents}
    `

    return {
      documentPrompt,
      imageUrls: images.map((image) => image.src)
    }
  }

  const getAllDocumentsAndImageUrls = async () => {
    if (!data?.data?.documents) throw new Error("No documents found")

    const { documents, images } = await convertAllDocumentsToMarkdown({
      allDocuments: data?.data.documents
    })

    return {
      documentPrompt: documents,
      imageUrls: images.map((image) => image.src)
    }
  }

  return (
    <DocumentContext.Provider
      value={{
        searchDocumentResults,
        setSearchDocumentResults,
        selectedDocument,
        setSelectedDocument,
        parentDocumentId,
        setParentDocumentId,
        documentTitles,
        setDocumentTitles,
        createPrompt,
        createAllDocumentPrompt,
        allMarkdownDocuments,
        setAllMarkdownDocuments,
        latestCommitId,
        setLatestCommitId,
        isDocumentUpdated,
        createCheckSpecificationImplementation,
        getAllDocumentsAndImageUrls
      }}
    >
      {children}
    </DocumentContext.Provider>
  )
}

export const useDocumentContext = (): DocumentContextType => {
  const context = useContext(DocumentContext)
  if (!context) {
    throw new Error("useDocumentContext must be used within an EditorProvider")
  }
  return context
}
