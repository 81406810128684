import { useRouter } from "next/router"
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from "react"
import { ProjectDomainModel } from "../api/domainModels/project"
import { TeamDomainModel } from "../api/domainModels/team"
import { ProjectApi } from "../api/queries/project"
import { ProjectResponseType } from "../api/responseBuilders/project"

interface TeamContextProps {
  currentSelectedTeam: TeamDomainModel | null
  setCurrentSelectedTeam: Dispatch<SetStateAction<TeamDomainModel | null>>
  teams: TeamDomainModel[]
  setTeams: Dispatch<SetStateAction<TeamDomainModel[]>>
  handleTeamChange: (team: TeamDomainModel) => Promise<void>

  currentSelectedProject: ProjectResponseType | null
  setCurrentSelectedProject: Dispatch<
    SetStateAction<ProjectResponseType | null>
  >
  projects: ProjectResponseType[]
  setProjects: Dispatch<SetStateAction<ProjectResponseType[]>>
  handleProjectChange: (project: ProjectDomainModel) => Promise<void>
  invitationLink: string | null
  setInvitationLink: Dispatch<SetStateAction<string | null>>
}

const TeamContext = createContext<TeamContextProps | null>(null)

function TeamProvider({ children }: { children: ReactNode }) {
  const [currentSelectedTeam, setCurrentSelectedTeam] =
    useState<TeamDomainModel | null>(null)
  const [teams, setTeams] = useState<TeamDomainModel[]>([])
  const [currentSelectedProject, setCurrentSelectedProject] =
    useState<ProjectResponseType | null>(null)
  const [projects, setProjects] = useState<ProjectResponseType[]>([])
  const [invitationLink, setInvitationLink] = useState<string | null>(null)

  const router = useRouter()

  useEffect(() => {
    if (!currentSelectedTeam) return
    ProjectApi.searchProjects({ teamId: currentSelectedTeam.id }).then(
      (res) => {
        setProjects(res.data.projects)
      }
    )
  }, [currentSelectedTeam])

  const handleTeamChange = async (team: TeamDomainModel) => {
    setCurrentSelectedTeam(team)
    await router.replace(`/${team.id}`)
  }

  const handleProjectChange = async (project: ProjectDomainModel) => {
    setCurrentSelectedProject(project)
    await router.replace(`/${currentSelectedTeam?.id}/${project.id}`)
  }

  return (
    <TeamContext.Provider
      value={{
        currentSelectedTeam,
        setCurrentSelectedTeam,
        teams,
        setTeams,
        handleTeamChange,
        currentSelectedProject,
        setCurrentSelectedProject,
        projects,
        setProjects,
        handleProjectChange,
        invitationLink,
        setInvitationLink
      }}
    >
      {children}
    </TeamContext.Provider>
  )
}

export const useTeamContext = () => {
  const context = useContext(TeamContext)
  if (!context) {
    throw new Error("useTeamContext must be used within an TeamProvider")
  }
  return context
}

export { TeamContext, TeamProvider }
