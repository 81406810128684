import clsx from "clsx"
import React, { forwardRef } from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

// Original variant styles
const variantStyles = {
  primary:
    "rounded-full bg-gray-800 py-1 px-3 text-slate-200 hover:bg-gray-700 dark:bg-sky-400/10 dark:text-sky-400 dark:ring-1 dark:ring-inset dark:ring-sky-400/20 dark:hover:bg-sky-400/10 dark:hover:text-sky-300 dark:hover:ring-sky-300",
  secondary:
    "rounded-full bg-gray-100 py-1 px-3 text-slate-900 hover:bg-gray-200 dark:bg-gray-950/40 dark:text-slate-400 dark:ring-1 dark:ring-inset dark:ring-slate-800 dark:hover:bg-gray-800 dark:hover:text-slate-300",
  filled:
    "rounded-full bg-gray-950 py-1 px-3 text-slate-200 hover:bg-gray-700 dark:bg-sky-500 dark:text-slate-200 dark:hover:bg-sky-400",
  outline:
    "rounded-full py-1 px-3 text-slate-700 ring-1 ring-inset ring-slate-900/10 hover:bg-gray-950/2.5 hover:text-slate-900 dark:text-slate-400 dark:ring-white/10 dark:hover:bg-white/5 dark:hover:text-slate-200",
  text: "text-sky-500 hover:text-sky-600 dark:text-sky-400 dark:hover:text-sky-500",
  solidBlue:
    "group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600",
  solidEmerald:
    "group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-emerald-600 text-white hover:text-slate-100 hover:bg-emerald-500 active:bg-emerald-800 active:text-emerald-100 focus-visible:outline-emerald-600",
  solidSlate:
    "group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-gray-950 text-white hover:bg-gray-700 hover:text-slate-100 active:bg-gray-800 active:text-slate-300 focus-visible:outline-slate-900",
  solidWhite:
    "group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-white text-slate-900 hover:bg-blue-50 active:bg-blue-200 active:text-slate-600 focus-visible:outline-white",
  outlineSlate:
    "group inline-flex ring-1 items-center justify-center rounded-full py-2 px-4 text-sm focus:outline-none",
  solidYellow:
    "group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-yellow-500 text-white  hover:bg-yellow-300 active:bg-yellow-600  focus-visible:outline-yellow-600",
  create:
    "inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-slate-200 shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm",
  update:
    "inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-slate-200 shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm",
  cancel:
    "inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm",
  destructive:
    "bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-300",
  ghost: "hover:bg-accent hover:text-accent-foreground"
}

// Define button variants and sizes using class-variance-authority
const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        primary: variantStyles.primary,
        secondary: variantStyles.secondary,
        filled: variantStyles.filled,
        outline: variantStyles.outline,
        text: variantStyles.text,
        solidBlue: variantStyles.solidBlue,
        solidEmerald: variantStyles.solidEmerald,
        solidSlate: variantStyles.solidSlate,
        solidWhite: variantStyles.solidWhite,
        outlineSlate: variantStyles.outlineSlate,
        solidYellow: variantStyles.solidYellow,
        create: variantStyles.create,
        update: variantStyles.update,
        cancel: variantStyles.cancel,
        destructive: variantStyles.destructive,
        ghost: variantStyles.ghost
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10"
      }
    },
    defaultVariants: {
      variant: "primary",
      size: "default"
    }
  }
)

interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  className?: string
  children: React.ReactNode
  disabled?: boolean
  loading?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = "primary",
      size = "default",
      asChild = false,
      className,
      children,
      onClick,
      disabled = false,
      loading = false,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button"

    const combinedClassName = clsx(
      buttonVariants({ variant, size, className }),
      (disabled || loading) && "opacity-50 cursor-not-allowed"
    )

    return (
      <Comp
        ref={ref}
        onClick={(e) => {
          if (!disabled && !loading && onClick) {
            onClick(e)
          }
        }}
        className={combinedClassName}
        disabled={disabled || loading}
        {...props}
      >
        {loading ? (
          <svg
            className="animate-spin h-5 w-5 text-current"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        ) : (
          children
        )}
      </Comp>
    )
  }
)
Button.displayName = "Button"
