import { generateHTML } from "@tiptap/html"
import { NodeHtmlMarkdown } from "node-html-markdown"
import superjson from "superjson"
import { DocumentResponseType } from "../../../api/responseBuilders/document"
import { defaultExtensions } from "../../../components/editor/extensions"
import { extractAllImagesFromDocuments } from "../../documents"

export const convertDocumentToMarkdown = (
  document: DocumentResponseType
): {
  documentName: string
  specifications: string
} => {
  const html = generateHTML(
    JSON.parse(document.specifications!),
    defaultExtensions
  )
  const markdown = NodeHtmlMarkdown.translate(html)

  return {
    documentName: document.name,
    specifications: markdown
  }
}

export const convertAllDocumentsToMarkdown = async ({
  allDocuments
}: { allDocuments: DocumentResponseType[] }) => {
  const images = await extractAllImagesFromDocuments(allDocuments)

  //@ts-ignore
  const convertToMarkdown = (document: DocumentResponseType) => {
    const specToUse =
      document.nextVersionSpecifications || document.specifications
    const html = generateHTML(JSON.parse(specToUse), [...defaultExtensions])
    const markdown = NodeHtmlMarkdown.translate(html)

    // Process children recursively
    // @ts-ignore
    const children = document.children
      ? document.children.map(convertToMarkdown)
      : []

    return {
      documentName: document.name,
      specifications: markdown,
      children: children
    }
  }

  const allDocumentsConverted = allDocuments.map(convertToMarkdown)

  return {
    documents: superjson.stringify(allDocumentsConverted),
    images
  }
}
