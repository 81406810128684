import { NodeViewWrapper, NodeViewWrapperProps } from "@tiptap/react"
import { useCallback, useState, useEffect } from "react"
import DOMPurify from "dompurify"
import { Panel, PanelHeadline } from "../../../ui/Panel"
import { Button } from "../../../../ui/button"
import { Icon } from "../../../ui/Icon"
import Magic from "../../../ui/icons/magic"
import CrazySpinner from "../../../ui/icons/crazy-spinner"
import { useTranslation } from "next-i18next"

export const AiWriterView = ({
  editor,
  node,
  getPos,
  deleteNode
}: NodeViewWrapperProps) => {
  const [isFetching, setIsFetching] = useState(node.attrs.content === "loading")
  const [previewText, setPreviewText] = useState<string | undefined>(undefined)
  const { t } = useTranslation("common", { useSuspense: true })

  useEffect(() => {
    // When the node is created, it should fetch AI content immediately
    if (node.attrs.content && node.attrs.content !== "loading") {
      const sanitizedHtml = DOMPurify.sanitize(node.attrs.content)
      setPreviewText(sanitizedHtml)
      setIsFetching(false)
    }
  }, [node.attrs.content])

  const insert = useCallback(() => {
    const from = getPos()
    const to = from + node.nodeSize

    editor.chain().focus().insertContentAt({ from, to }, previewText).run()
  }, [editor, previewText, getPos, node.nodeSize, deleteNode])

  const discard = useCallback(() => {
    deleteNode()
  }, [deleteNode])

  return (
    <NodeViewWrapper data-drag-handle>
      <Panel noShadow className="w-full">
        {isFetching && (
          <div className="flex pt-2 items-center px-4 text-sm font-medium text-muted-foreground text-purple-500">
            <Magic className="mr-2 h-4 w-4 shrink-0" />
            {t("AI is thinking")}
            <div className="ml-2 mt-1">
              <CrazySpinner />
            </div>
          </div>
        )}
        <div className="flex flex-col p-1">
          {previewText && (
            <>
              <PanelHeadline>{t("preview")}</PanelHeadline>
              <div
                className="bg-white dark:bg-black border-l-4 border-neutral-100 dark:border-neutral-700 text-black dark:text-white text-base max-h-[14rem] mb-4 ml-2.5 overflow-y-auto px-4 relative"
                dangerouslySetInnerHTML={{ __html: previewText }}
              />
              <div className="flex justify-between w-auto gap-1">
                <Button
                  variant="destructive"
                  className="text-red-500 hover:bg-red-500/10 hover:text-red-500"
                  onClick={discard}
                >
                  <Icon name="Trash" />
                  {t("discard")}
                </Button>
                <Button
                  variant="solidSlate"
                  onClick={insert}
                  disabled={!previewText}
                >
                  <Icon name="Check" />
                  {t("insert")}
                </Button>
              </div>
            </>
          )}
        </div>
      </Panel>
    </NodeViewWrapper>
  )
}
