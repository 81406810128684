import { uploadFn } from "../image/image-upload"
import { Group } from "./types"

export const GROUPS: Group[] = [
  {
    name: "format",
    title: "Format",
    commands: [
      {
        name: "heading1",
        label: "Heading 1",
        iconName: "Heading1",
        description: "High priority section title",
        aliases: ["h1"],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 1 }).run()
        }
      },
      {
        name: "heading2",
        label: "Heading 2",
        iconName: "Heading2",
        description: "Medium priority section title",
        aliases: ["h2"],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 2 }).run()
        }
      },
      {
        name: "heading3",
        label: "Heading 3",
        iconName: "Heading3",
        description: "Low priority section title",
        aliases: ["h3"],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 3 }).run()
        }
      },
      {
        name: "bulletList",
        label: "Bullet List",
        iconName: "List",
        description: "Unordered list of items",
        aliases: ["ul"],
        action: (editor) => {
          editor.chain().focus().toggleBulletList().run()
        }
      },
      {
        name: "numberedList",
        label: "Numbered List",
        iconName: "ListOrdered",
        description: "Ordered list of items",
        aliases: ["ol"],
        action: (editor) => {
          editor.chain().focus().toggleOrderedList().run()
        }
      },
      {
        name: "taskList",
        label: "Task List",
        iconName: "ListTodo",
        description: "Task list with todo items",
        aliases: ["todo"],
        action: (editor) => {
          editor.chain().focus().toggleTaskList().run()
        }
      },
      {
        name: "blockquote",
        label: "Blockquote",
        iconName: "Quote",
        description: "Element for quoting",
        action: (editor) => {
          editor.chain().focus().setBlockquote().run()
        }
      },
      {
        name: "codeBlock",
        label: "Code Block",
        iconName: "SquareCode",
        description: "Code block with syntax highlighting",
        shouldBeHidden: (editor) => editor.isActive("columns"),
        action: (editor) => {
          editor.chain().focus().setCodeBlock().run()
        }
      }
    ]
  },
  {
    name: "insert",
    title: "Insert",
    commands: [
      {
        name: "image",
        label: "Image",
        iconName: "Image",
        description: "Insert an image",
        aliases: ["img"],
        action: (editor) => {
          const input = document.createElement("input")
          input.type = "file"
          input.accept = "image/*"
          input.onchange = async () => {
            if (input.files?.length) {
              const file = input.files[0]
              const pos = editor.view.state.selection.from
              uploadFn(file, editor.view, pos)
            }
          }
          input.click()
        }
      },
      {
        name: "linkToPage",
        label: "Link to page",
        iconName: "CircleArrowRight",
        description: "Link to an existing page",
        aliases: ["page"],
        action: (editor) => {
          editor.chain().focus().linkToPage().run()
        }
      },
      {
        name: "horizontalRule",
        label: "Horizontal Rule",
        iconName: "Minus",
        description: "Insert a horizontal divider",
        aliases: ["hr"],
        action: (editor) => {
          editor.chain().focus().setHorizontalRule().run()
        }
      }
    ]
  },
  {
    name: "table",
    title: "table",
    commands: [
      {
        name: "Add table",
        label: "Add table",
        iconName: "Table",
        description: "Insert a table",
        shouldBeHidden: (editor) => editor.isActive("columns"),
        action: (editor) => {
          editor
            .chain()
            .focus()
            .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
            .run()
        }
      },
      {
        name: "addRowBefore",
        label: "Add Row Before",
        iconName: "ArrowUp",
        description: "Add a row before the current row.",
        action: (editor) => {
          editor.chain().focus().addRowBefore().run()
        }
      },
      {
        name: "addRowAfter",
        label: "Add Row After",
        iconName: "ArrowDown",
        description: "Add a row after the current row.",
        action: (editor) => {
          editor.chain().focus().addRowAfter().run()
        }
      },
      {
        name: "addColumnBefore",
        label: "Add Column Before",
        iconName: "ArrowLeft",
        description: "Add a column before the current column.",
        action: (editor) => {
          editor.chain().focus().addColumnBefore().run()
        }
      },
      {
        name: "addColumnAfter",
        label: "Add Column After",
        iconName: "ArrowRight",
        description: "Add a column after the current column.",
        action: (editor) => {
          editor.chain().focus().addColumnAfter().run()
        }
      },
      {
        name: "mergeCells",
        label: "Merge Cells",
        iconName: "Merge",
        description: "Merge selected cells.",
        action: (editor) => {
          editor.chain().focus().mergeCells().run()
        }
      },
      {
        name: "splitCell",
        label: "Split Cells",
        iconName: "Split",
        description: "Split merged cells.",
        action: (editor) => {
          editor.chain().focus().splitCell().run()
        }
      },
      {
        name: "deleteRow",
        label: "Delete Row",
        iconName: "Trash2",
        description: "Delete the current row.",
        action: (editor) => {
          editor.chain().focus().deleteRow().run()
        }
      },
      {
        name: "deleteColumn",
        label: "Delete Column",
        iconName: "Trash2",
        description: "Delete the current column.",
        action: (editor) => {
          editor.chain().focus().deleteColumn().run()
        }
      },
      {
        name: "Delete table",
        label: "Delete Table",
        iconName: "Trash2",
        description: "Insert a table",
        shouldBeHidden: (editor) => editor.isActive("columns"),
        action: (editor) => {
          editor.chain().focus().deleteTable().run()
        }
      }
    ]
  }
]

export default GROUPS
