import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from "react"
import { toBoolean } from "../utils"

interface HeaderContextProps {
  showAIChatSidebar: boolean
  setShowAIChatSidebarHandler: (value: boolean) => void
  onPaneDragEnd: (sizes: number[]) => void
  threePaneSizes: number[]
  showVersioningModal: boolean
  setShowVersioningModal: (value: boolean) => void
  isReadOnly: boolean
  setIsReadOnly: (value: boolean) => void
}

export const THREE_PANE_SIZES_LOCAL_STORAGE_KEY =
  "THREE_PANE_SIZES_LOCAL_STORAGE_KEY"
export const SHOW_TEST_CASE_SIDEBAR_LOCAL_STORAGE_KEY =
  "SHOW_TEST_CASE_SIDEBAR_LOCAL_STORAGE_KEY"
export const SHOW_AI_CHAT_SIDEBAR_LOCAL_STORAGE_KEY =
  "SHOW_AI_CHAT_SIDEBAR_LOCAL_STORAGE_KEY"
const HeaderContext = createContext<HeaderContextProps | null>(null)

function HeaderProvider({ children }: { children: ReactNode }) {
  const [showAIChatSidebar, setShowAIChatSidebar] = useState(false)
  const [showVersioningModal, setShowVersioningModal] = useState(false)
  const [threePaneSizes, setThreePaneSizes] = useState<number[]>([
    300, 500, 500
  ])

  const setShowAIChatSidebarHandler = (value: boolean) => {
    setShowAIChatSidebar(value)
    localStorage.setItem(
      SHOW_AI_CHAT_SIDEBAR_LOCAL_STORAGE_KEY,
      value.toString()
    )
  }

  const [isReadOnly, setIsReadOnly] = useState(() => {
    if (typeof window !== "undefined") {
      const storedValue = localStorage.getItem("readonly")
      return storedValue === null ? true : JSON.parse(storedValue)
    }
    return true
  })

  const setIsReadOnlyHandler = (value: boolean) => {
    setIsReadOnly(value)
    localStorage.setItem("readonly", JSON.stringify(value))
  }

  useEffect(() => {
    const newShowAIChatSidebar = toBoolean(
      localStorage.getItem(SHOW_AI_CHAT_SIDEBAR_LOCAL_STORAGE_KEY)
    )
    setShowAIChatSidebar(newShowAIChatSidebar)
    if (window !== undefined) {
      const threePaneSavedSizes = localStorage.getItem(
        THREE_PANE_SIZES_LOCAL_STORAGE_KEY
      )
      if (threePaneSavedSizes) {
        const parsedSavedSizes = JSON.parse(threePaneSavedSizes)
        setThreePaneSizes(parsedSavedSizes)
      }
    }
  }, [])

  const onPaneDragEnd = (sizes: number[]) => {
    // TODO: fix bug
    const newSizes = [
      sizes[0],
      sizes[1],
      threePaneSizes[2] === 0 ? sizes[2] : threePaneSizes[2]
    ]
    localStorage.setItem(
      THREE_PANE_SIZES_LOCAL_STORAGE_KEY,
      JSON.stringify(newSizes)
    )
    setThreePaneSizes(newSizes)
  }

  return (
    <HeaderContext.Provider
      value={{
        setShowAIChatSidebarHandler,
        showAIChatSidebar,
        onPaneDragEnd,
        threePaneSizes,
        showVersioningModal,
        setShowVersioningModal,
        isReadOnly,
        setIsReadOnly: setIsReadOnlyHandler
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

export const useHeaderContext = () => {
  const context = useContext(HeaderContext)
  if (!context) {
    throw new Error("useHeaderContext must be used within a HeaderProvider")
  }
  return context
}

export { HeaderContext, HeaderProvider }
