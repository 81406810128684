import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import axios from "src/utils/axios"
import { PostDocumentBody } from "../../validation/documents"
import {
  SearchDocumentsOrder,
  SearchDocumentsQueryType
} from "../../validation/documents/search"
import {
  ArchiveDocumentParameterType,
  ContinueWritingWithAIParameterType,
  UpdateDocumentParameterType
} from "../applicationServices/document"
import {
  DocumentResponseType,
  DocumentsResponseType
} from "../responseBuilders/document"

export class DocumentApi {
  public static async getCollaborationToken() {
    const res = await axios.get<{
      token: string
    }>("/api/collaboration-token")
    return res.data
  }

  public static async getDocument({
    projectId,
    documentId
  }: { projectId?: string; documentId: string }) {
    const res = await axios.get<DocumentResponseType>(
      `/api/v1.0/projects/${projectId}/documents/${documentId}`
    )
    return res.data
  }

  public static async searchDocuments({
    projectId,
    param
  }: {
    projectId?: string
    param: SearchDocumentsQueryType
  }) {
    const res = await axios.get<DocumentsResponseType>(
      `/api/v1.0/projects/${projectId}/documents/search`,
      {
        params: param
      }
    )
    return res
  }

  public static async createDocument({
    projectId,
    param
  }: {
    projectId?: string
    param: PostDocumentBody
  }) {
    const res = await axios.post<DocumentResponseType>(
      `/api/v1.0/projects/${projectId}/documents`,
      param
    )
    return res.data
  }

  public static async updateDocument({
    projectId,
    documentId,
    params
  }: Omit<UpdateDocumentParameterType, "userId">) {
    const res = await axios.patch<DocumentResponseType>(
      `/api/v1.0/projects/${projectId}/documents/${documentId}`,
      params
    )
    return res.data
  }

  public static async archiveDocument(
    params: Omit<ArchiveDocumentParameterType, "userId">
  ) {
    const res = await axios.patch<DocumentResponseType>(
      `/api/v1.0/projects/${params.projectId}/documents/${params.documentId}/archive`,
      {
        archive: params.archive
      }
    )
    return res.data
  }

  public static async deleteDocument({
    projectId,
    documentId
  }: { projectId?: string; documentId: string }) {
    const res = await axios.delete<DocumentResponseType>(
      `/api/v1.0/projects/${projectId}/documents/${documentId}`
    )
    return res.data
  }

  public static async continueWritingWithAI({
    projectId,
    documentId,
    previousText,
    followingText
  }: Omit<ContinueWritingWithAIParameterType, "userId">) {
    const res = await axios.post<string>(
      `/api/v1.0/projects/${projectId}/documents/${documentId}/suggestions`,
      {
        previousText,
        followingText
      }
    )
    return res.data
  }
}

export const useCreateDocument = ({ projectId }: { projectId: string }) => {
  const queryClient = useQueryClient()
  return useMutation(
    (param: PostDocumentBody) =>
      DocumentApi.createDocument({ projectId, param }),
    {
      onSuccess: () => queryClient.invalidateQueries(["documents", "search"])
    }
  )
}

export const useGetCollaborationToken = () => {
  return useQuery({
    queryKey: ["collaboration-token"],
    queryFn: DocumentApi.getCollaborationToken,
    staleTime: Infinity,
    cacheTime: Infinity
  })
}

export const useGetDocument = ({
  projectId,
  documentId
}: { projectId: string; documentId?: string }) =>
  useQuery({
    queryKey: ["documents", "get", documentId],
    queryFn: () => {
      if (documentId) return DocumentApi.getDocument({ projectId, documentId })
    },
    enabled: !!documentId,
    staleTime: Infinity,
    cacheTime: Infinity
  })

type Params = {
  invalidateDocumentSearch?: boolean
  projectId?: string
}

export const useUpdateDocument = (params: Params) => {
  const { invalidateDocumentSearch = false } = params
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      projectId,
      documentId,
      params
    }: Omit<UpdateDocumentParameterType, "userId">) =>
      DocumentApi.updateDocument({
        projectId,
        documentId,
        params
      }),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["documents", "get", data.id])
      if (invalidateDocumentSearch) {
        queryClient.invalidateQueries(["documents", "search"])
      }
    }
  })
}

// // ENHANCE: reuse argument type
export function useSearchDocuments(param: {
  searchText?: string
  versionId?: string
  offset?: number
  limit?: number
  order?: SearchDocumentsOrder
  sort?: "asc" | "desc"
  enabled?: boolean
  projectId?: string
  archived?: boolean
  staleTime?: number
  cacheTime?: number
  fields?: string[]
}) {
  const {
    searchText = "",
    versionId,
    offset = 0,
    limit,
    order,
    sort = "asc",
    enabled = true,
    projectId,
    archived = false,
    staleTime = Infinity,
    cacheTime = Infinity,
    fields = []
  } = param

  let fieldsString = undefined
  if (fields.length > 0) {
    fieldsString = fields.join(",")
  }

  return useQuery({
    queryKey: ["documents", "search", param],
    queryFn: ({ pageParam = offset }) =>
      DocumentApi.searchDocuments({
        projectId,
        param: {
          order: order,
          sort: sort,
          title: searchText,
          offset: pageParam,
          versionId: versionId,
          archived: archived,
          limit: limit,
          fields: fieldsString // convert fields array to comma-separated string
        }
      }),
    staleTime,
    cacheTime,
    enabled
  })
}

export const useDeleteDocument = (params: Params) => {
  const { invalidateDocumentSearch = false } = params
  const queryClient = useQueryClient()
  return useMutation(
    (DocumentId: string) =>
      DocumentApi.deleteDocument({
        projectId: params.projectId,
        documentId: DocumentId
      }),
    {
      onSuccess: async () => {
        if (invalidateDocumentSearch) {
          await queryClient.invalidateQueries(["documents", "search"])
        }
      }
    }
  )
}

export const useArchiveDocument = (params: Params) => {
  const { invalidateDocumentSearch = false } = params
  const queryClient = useQueryClient()
  return useMutation(
    (params: Omit<ArchiveDocumentParameterType, "userId">) =>
      DocumentApi.archiveDocument({
        ...params
      }),
    {
      onSuccess: async () => {
        if (invalidateDocumentSearch) {
          await queryClient.invalidateQueries(["documents", "search"])
        }
      }
    }
  )
}
