import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import axios from "src/utils/axios"
import { UpdateUserParameterType } from "../applicationServices/user"
import { UserResponseType } from "../responseBuilders/user"
import { useState } from "react"
import { createClient } from "../../utils/supabase/component"

class UserApi {
  public static async get(id?: string) {
    const res = await axios.get<UserResponseType>(`/api/v1.0/users/${id}`)
    return res.data
  }
  public static async patch({
    id,
    params
  }: {
    id: string
    params: Omit<UpdateUserParameterType, "userId">
  }) {
    const res = await axios.patch<UserResponseType>(
      `/api/v1.0/users/${id}`,
      params
    )
    return res.data
  }
}

export const useGetUser = (id?: string) => {
  if (!id)
    return useQuery(["user", id], () => {
      return Promise.resolve(null)
    })
  // const router = useRouter()
  return useQuery(["user", id], () => UserApi.get(id), {
    enabled: !!id,
    staleTime: Infinity,
    cacheTime: Infinity
    // onError: () => {
    //   router.push("/auth/sign-in")
    // }
  })
}

type InvalidateQuery = {
  invalidateGetUser?: boolean
}

export const useUpdateUser = (params: InvalidateQuery) => {
  const { invalidateGetUser = false } = params
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      userId,
      params
    }: { userId: string; params: Omit<UpdateUserParameterType, "userId"> }) =>
      UserApi.patch({ id: userId, params }),
    onSettled: async (_1, _2, variable) => {
      if (invalidateGetUser) {
        await queryClient.invalidateQueries({
          queryKey: ["user", variable.userId]
        })
      }
    }
  })
}

export const useUpdateUserMetadata = () => {
  const supabase = createClient()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const updateUserMetadata = async (metadata: { [key: string]: any }) => {
    setIsLoading(true)
    setError(null)

    try {
      const { data, error } = await supabase.auth.updateUser({
        data: metadata
      })

      if (error) throw error

      return data.user
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      } else {
        setError("An unknown error occurred")
      }
      return null
    } finally {
      setIsLoading(false)
    }
  }

  return { updateUserMetadata, isLoading, error }
}
