import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState
} from "react"
import { TicketResponseType } from "../api/responseBuilders/ticket"

interface TicketContextProps {
  openTicketDetailModal: boolean
  setOpenTicketDetailModal: Dispatch<SetStateAction<boolean>>
  selectedTicket: TicketResponseType | null
  setSelectedTicket: Dispatch<SetStateAction<TicketResponseType | null>>
}

const TicketContext = createContext<TicketContextProps | null>(null)

function TicketProvider({ children }: { children: ReactNode }) {
  const [openTicketDetailModal, setOpenTicketDetailModal] =
    useState<boolean>(false)
  const [selectedTicket, setSelectedTicket] =
    useState<TicketResponseType | null>(null)
  return (
    <TicketContext.Provider
      value={{
        openTicketDetailModal,
        setOpenTicketDetailModal,
        selectedTicket,
        setSelectedTicket
      }}
    >
      {children}
    </TicketContext.Provider>
  )
}

export const useTicketContext = () => {
  const context = useContext(TicketContext)
  if (!context) {
    throw new Error("useTicketContext must be used within a TicketProvider")
  }
  return context
}

export { TicketContext, TicketProvider }
